html {
  box-sizing: border-box;
}

ul {
  padding: 0;
}

button {
  background: none;
  border: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  text-decoration: none;
}

:focus {
  outline: rgb(255, 182, 0) solid 2px;
  outline-offset: 1px;
}
