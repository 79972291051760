.rain-drop {
  z-index: 9999999999;
  position: fixed;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-200%);
  transition: transform 5s cubic-bezier(0.5, 0, 0.75, 0), opacity 5s cubic-bezier(0.5, 0, 0.75, 0);
  pointer-events: none;
}

.exploding-icon {
  z-index: 9999999999;
  position: fixed;
  width: 16px;
  height: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.75s cubic-bezier(0.16, 1, 0.3, 1),
    opacity 0.75s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
  pointer-events: none;
}
